@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  padding: 0;
  margin: 0;
  font-family: 'Inter';
}

html {
  height: 100%;
  max-width: 100vw;
}

body {
  height: 100%;
  min-height: 100vh;
}

#__next {
  height: 100%;
  max-width: 100vw;
}

.custom-option {
  transition: background 60ms;
  font-size: 12px !important;
}
.custom-option:hover {
  transition-delay: 10ms;
  background: #ececec;
}
.custom-option.custom-select__option--is-focused {
  background: none;
}
.custom-option.custom-select__option--is-selected {
  background: #2684ff;
}

.ant-menu-item:not(.ant-menu-item-selected):hover {
  color: #090909 !important;
  background-color: #eeeeee !important;
}

.ant-menu-item:not(.ant-menu-item-selected).ant-hover-none:hover {
  color: rgb(57, 51, 212) !important;
  background-color: rgb(238, 240, 248) !important;
}

.ace_layer.ace_gutter-layer.ace_folding-enabled {
  width: 30px;
}

.ace_editor {
  border-radius: 5px;
  font-family: "Monaco", "Menlo", "Ubuntu Mono", "Droid Sans Mono", "Consolas", monospace !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  letter-spacing: 0 !important;
}

.ace_scroller {
  left: 30px;
}

.ace_content {
  padding-top: 10px;
}

.ace_gutter {
  padding-top: 10px;
  width: 30px;
}

.ace_scrollbar {
  display: none;
}

.ace_editor,
.ace_editor * {
  font-family: "Monaco", "Menlo", "Ubuntu Mono", "Droid Sans Mono", "Consolas", monospace !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  letter-spacing: 0 !important;
}



/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
