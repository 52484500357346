[cmdk-root] {
  max-width: 640px;
  width: 100%;
  left: 50%;
  transform: translate(-50%, 50px);
  position: absolute;
  padding: 8px;
  background: #fbfbfb;
  border-radius: 6px;
  overflow: hidden;
  border: 1px solid #cdcdcd;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  z-index: 999;
}

[cmdk-item] {
  content-visibility: auto;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.1s;

  &:hover {
    background: rgba(153, 153, 153, 0.2);
  }
}

[cmdk-list] {
  height: min(400px, 500px);
  overflow: auto;
  overscroll-behavior: contain;
  transition: 100ms ease;
  transition-property: height;
  border-radius: 4px;
}

[cmdk-input] {
  outline: none;
  width: 100%;
  background: #fbfbfb;
  color: #363636;
  padding: 8px;
  &:focus {
    outline: none;
  }
}
