.ant-layout-sider {
  background-color: rgb(248, 248, 248) !important;
  border-right: 1px solid #dbdbdb;
}

.ant-layout-sider-collapsed {
  max-width: 40px !important;
  width: 40px !important;
  min-width: 40px !important;
}

.ant-menu {
  background-color: rgb(248, 248, 248) !important;
  border-right: 1px solid #dbdbdb;
}

.ant-menu-item {
  height: 40px !important;
  padding: 0;
  padding-left: 4px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  padding: 0 !important;
  padding-left: 4px !important;
  color: #2c2c2c;

  &:hover {
    color: #090909 !important;
    background-color: transparent !important;
  }

  &:focus {
    background-color: transparent !important;
  }

  .ant-menu-title-content {
    line-height: 20px;
  }
}

.ant-menu-title-content {
  max-width: fit-content;
}

.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item-icon + span {
  display: none;
}

.ant-menu-item-selected {
  background-color: transparent !important;
  color: #111 !important;
}

.ant-menu-title-content {
  transition: none !important;
}

.ant-layout.site-layout {
  min-width: 1000px;
}

.ant-layout.ant-layout-has-sider {
  background-color: rgb(248, 248, 248) !important;
}
